import React from "react";
import "../style.css";
import { Link, matchRoutes } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

const Image = () => {
  return (
    <>
      <div className='contact-image'>
        <span>SİZİ ARAYALIM MI?</span>
        <div>PROJELERİMİZ VE SATIŞ SONRASI TEKNİK</div>
        <div>HİZMETLERİMİZ HAKKINDA DETAYLI BİLGİ İÇİN</div>
        <button>
          <Link
            as={Link}
            to='/contact'
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            İLETİŞİM
          </Link>
        </button>
      </div>
    </>
  );
};

export default Image;
