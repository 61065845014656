import React from "react";
import "../style.css";
import { Link } from "react-router-dom";

const Bilser = () => {
  return (
    <>
      <div className='bilser'>
        <h2>Bilser Ailesi</h2>
        <div className='container'>
          <img src='images/logo.png'></img>
          <img src='images/mayakonut.PNG'></img>
          <img src='images/ikon.PNG'></img>
          <img src='images/akre.PNG'></img>
        </div>
      </div>
    </>
  );
};

export default Bilser;
