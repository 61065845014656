import React from "react";
import {Helmet} from "react-helmet";
import Carousel from "../Components/Carousel";
import Header from "../Components/Header";
import Info from "../Components/Info";
import Projects from "../Components/Projects";
import Footer from "../Components/Footer";
import Image from "../Components/Image";
import Bilser from "../Components/Bilser";


const HomePage = () => {
  return (
    <>
      <Helmet>
          <title>Bilser Mimarlık</title>
          <meta name="description" content="Bilser Mimarlık"/>
          <meta name="keywords" content="Bilser Mimarlık, Bilser Mimarlık Bursa, ikon 7700, Bademli Villa"/>
      </Helmet>
      <Header></Header>
      <Carousel></Carousel>
      <Info></Info>
      <Projects></Projects>
      <Image></Image>
      <Bilser></Bilser>
      <Footer></Footer>
    </>
  );
};
export default HomePage;
