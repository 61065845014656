import Carousel from "react-bootstrap/Carousel";
import React, { useState, useEffect } from 'react';
import axios from 'axios';



function UncontrolledExample() {
  const [posts, setData] = useState([]);
  
  
useEffect(() => {
  axios
  .get('https://api.bilsermimarlik.com.tr/api/sliderImage')
  .then((response) => {
  const json = response.data;
  console.log('json', json);
  setData(json);
  })
  .catch((error) => {
  console.log(error);
  });
  }, []);

  return (
    <Carousel>
      {posts.map((post, i) => ( 
        i==0 ?
      (<Carousel.Item>
        <img
          className='d-block w-100'
          src= {post.path}
          alt='Third slide'
        />
      </Carousel.Item>)
      :
      (
      <Carousel.Item>
        <img
          className='d-block w-100 '
          src= {post.path}
          alt='First slide'
        />
      </Carousel.Item>
      )
      ))}
    </Carousel>
  );
}

export default UncontrolledExample;
