import React from "react";
import HomePage from "./pages/HomePage";
import "bootstrap/dist/css/bootstrap.min.css";
import Kurumsal from "./pages/Kurumsal";
import Planlanan from "./pages/Planlanan";
import Devameden from "./pages/Devameden";
import Tamamlanan from "./pages/Tamamlanan";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import ProjectDetail from "./pages/ProjectDetail";

function App() {
  return (
    <>
      <Router>
        <Routes>{<Route path='/' element={<HomePage />} />}</Routes>
        <Routes>{<Route path='/kurumsal' element={<Kurumsal />} />}</Routes>
        <Routes>{<Route path='/devameden' element={<Devameden />} />}</Routes>
        <Routes>{<Route path='/tamamlanan' element={<Tamamlanan />} />}</Routes>
        <Routes>{<Route path='/contact' element={<Contact />} />}</Routes>
        <Routes>{<Route path='/projectdetail/:id' element={<ProjectDetail />} />}</Routes>
      </Router>
    </>
  );
}

export default App;
