import React, { useState, useEffect } from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import axios from 'axios';

const Kurumsal = () => {

  const [posts, setData] = useState([]);
  useEffect(() => {
    axios
    .get('https://bilserwebapi.ileydaburnaz.com.tr/api/setting/1')
    .then((response) => {
    const json = response.data;
    console.log('json', json);
    setData(json);
    })
    .catch((error) => {
    console.log(error);
    });
    }, []);

      const [postImg, setDataImg] = useState([]);  
      useEffect(() => {
        axios
        .get('https://api.bilsermimarlik.com.tr/api/aboutimages/1')
        .then((response) => {
        const json = response.data;
        console.log('json', json);
        setDataImg(json);
        })
        .catch((error) => {
        console.log(error);
        });
        }, []);

  return (
    <>
      <Header></Header>
      <div className='header-title'>
        <span>KURUMSAL</span>
      </div>
      <div className='main-kurumsal'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-12 '>
              <ul className='list-unstyled'>
                <div className='kurumsal-image'>
                  <img src={postImg.path}></img>
                </div>
              </ul>
            </div>
            <div className='col-md-6 col-sm-12 '>
              <ul>
                <div className='kurumsal-info' dangerouslySetInnerHTML={{__html: posts.aboutText}}>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Kurumsal;
