import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Sonnet from "./Sonnet";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import "../style.css";

const Projects = () => {

  const [posts, setData] = useState([]);
  useEffect(() => {
    axios
    .get('https://api.bilsermimarlik.com.tr/api/Project')
    .then((response) => {
    const json = response.data;
    console.log('json', json);
    setData(json);
    })
    .catch((error) => {
    console.log(error);
    });
    }, []);

    const [postsOld, setDataOld] = useState([]);
    useEffect(() => {
      axios
      .get('https://api.bilsermimarlik.com.tr/api/Project/old')
      .then((response) => {
      const json = response.data;
      console.log('json', json);
      setDataOld(json);
      })
      .catch((error) => {
      console.log(error);
      });
      }, []);

  return (
    <>
      <Tabs
        defaultActiveKey='tab-1'
        id='uncontrolled-tab-example'
        className='mb-6 justify-content-center projects-tabs '
      >
        <Tab
          className='projects-tab'
          eventKey='tab-1'
          title='Tamamlanan Projeler'
        >
          <CardGroup className='card-group'>
          {postsOld.map((postOld, i) => ( 
                (
                  <div class='col-sm-4'>
                  <Card className='projects-card'>
                    <Card.Img
                      variant='top'
                      src={postOld.path}
                      className='card-image'
                    />
                    <Card.Body>
                      <Card.Title>{postOld.name}</Card.Title>
                      <Card.Link href={`/projectdetail/${postOld.id}`} className='card-link'>
                        Proje Detayı
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </div>
                )
                ))}
          </CardGroup>
          <Sonnet />
        </Tab>
        <Tab
          className='projects-tab'
          eventKey='tab-2'
          title='Devam Eden Projeler'
        >
          <CardGroup className='card-group'>
          {posts.map((post, i) => ( 
                (
                  <div class='col-sm-4'>
                  <Card className='projects-card'>
                    <Card.Img
                      variant='top'
                      src={post.path}
                      className='card-image'
                    />
                    <Card.Body>
                      <Card.Title className='card-title'>
                      {post.name}
                      </Card.Title>
                      <Card.Link href={`/projectdetail/${post.id}`} className='card-link'>
                        Proje Detayı
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </div>
                )
                ))}
          </CardGroup>
          <Sonnet />
        </Tab>
      </Tabs>
    </>
  );
};

export default Projects;
