import React from "react";
import "../style.css";

const Info = () => {
  return (
    <>
      <div className='info'>
        <h2>Bilser Mimarlık</h2>
        <p>
          Bilser Mimarlık, yaptığı her proje ile ihtiyaca yönelik esnek çözümler
          sunarken yaşam kalitesi standartlarında yukarı çeken yapısıyla göze
          çarpmaktadır. Bu amaçla Bilser Mimarlık, şehrin en iddialı
          lokasyonlarında, zemin özelliklerine uygun yapıları, en son
          teknolojilerle kurulu, deprem yönetmeliğine uygun, arazinin
          gerektirdiği inşaat tekniğini göz önünde bulundurarak, dayanıklı,
          güvenli ve çağdaş yaşam alanları geliştirmektedir.
        </p>
      </div>
    </>
  );
};

export default Info;
