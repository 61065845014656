import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import {Route, Link, Routes, useParams} from 'react-router-dom';
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import "../style.css";
import "../projectdetail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLocationDot,
  faLayerGroup,
  faClipboardList,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";

const ProjectDetail = () => {

  const { id } = useParams();
  const [posts, setData] = useState([]);
  const url = "https://api.bilsermimarlik.com.tr/api/Project/" + id;
  useEffect(() => {
    axios
    .get(url)
    .then((response) => {
    const json = response.data;
    console.log('json', json);
    setData(json);
    })
    .catch((error) => {
    console.log(error);
    });
    }, []);

    const [postImages, setDataImages] = useState([]);
    const url2 = "https://api.bilsermimarlik.com.tr/api/projectImage/" + id;
    useEffect(() => {
      axios
      .get(url2)
      .then((response) => {
      const json = response.data;
      console.log('json', json);
      setDataImages(json);
      })
      .catch((error) => {
      console.log(error);
      });
      }, []);


      const [postFloors, setDataFloors] = useState([]);
    const url3 = "https://api.bilsermimarlik.com.tr/api/FloorPlan/" + id;
    useEffect(() => {
      axios
      .get(url3)
      .then((response) => {
      const json = response.data;
      console.log('json', json);
      setDataFloors(json);
      })
      .catch((error) => {
      console.log(error);
      });
      }, []);


  return (
    <>
      <Header></Header>
      <div className='header-title'>
        <span>{posts.name}</span>
      </div>
      <div className='project-info'>
        <h5>Proje Hakkında</h5>
        <p>
        {posts.text}
        </p>
      </div>
      <div className='project-card'>
        <div className='row'>
          <div className='project-details col-md-6 col-sm-12 col-lg-3'>
            <ul>
              <li>
                <div className='project-icon'>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ fontSize: "36px" }}
                  />
                  <span>{posts.adresses}</span>
                  <span>{posts.adresses2}</span>
                </div>
              </li>
            </ul>
          </div>
          <div className='project-details col-md-6 col-sm-12 col-lg-3'>
            <ul>
              <li>
                <div className='project-icon'>
                  <FontAwesomeIcon
                    icon={faLayerGroup}
                    style={{ fontSize: "36px" }}
                  />
                  <span>{posts.m2}</span>
                  <span>{posts.m22}</span>
                </div>
              </li>
            </ul>
          </div>
          <div className='project-details col-md-6 col-sm-12 col-lg-3'>
            <ul>
              <li>
                <div className='project-icon'>
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    style={{ fontSize: "36px" }}
                  />
                  <span>{posts.info}</span>
                  <span>{posts.info2}</span>
                </div>
              </li>
            </ul>
          </div>
          <div className='project-details col-md-6 col-sm-12 col-lg-3'>
            <ul>
              <li>
                <div className='project-icon'>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    style={{ fontSize: "36px" }}
                  />
                  <span>{posts.date}</span>
                  <span>{posts.date2}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='project-images'>
        <h5
          style={{
            textAlign: "center",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          Görseller
        </h5>
        <div style={{ marginBottom: "40px" }}>
        <div class='gallery-wrapper'>
        {postImages.map((image, i) => ( 
          (
            <div class='image-wrapper' style={{ maxHeight: "105", maxWidth:"210" }}>
              <a href={`#lightbox-image-${i}`}>
                <img src={`${image.path}`} alt='' style={{ maxHeight: "105", maxHeight:"210" }}/>
              </a>
            </div>
          )
          ))}
          </div>
          <div class='gallery-lightboxes'>
          {postImages.map((image, i) => ( 
            (
              <div class='image-lightbox' id={`lightbox-image-${i}`}>
                <div class='image-lightbox-wrapper'>
                  <a href='#' class='close'></a>
                  <a href={`#lightbox-image-${i-1}`} class='arrow-left'></a>
                  <a href={`#lightbox-image-${i+1}`} class='arrow-right'></a>
                  <img src={`${image.path}`} alt='' />
                </div>
              </div>
            )
            ))}
          </div>
        </div>
      </div>

              
      {postFloors.length > 0 && 
            <div className='project-floor'>
            <h5
              style={{
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
              Kat Planları
            </h5>
            <div style={{ marginBottom: "40px" }}>
            <div class='gallery-wrapper'>
            {postFloors.map((floor, i) => ( 
              (
                <div class='image-wrapper'>
                  <a href={`#lightbox-image-${i}`}>
                    <img src={`${floor.path}`} alt=''/>
                  </a>
                </div>
              )
              ))}
              </div>
              <div class='gallery-lightboxes'>
              {postFloors.map((floor, k) => ( 
                (
                  <div class='image-lightbox' id={`lightbox-image-${k}`}>
                    <div class='image-lightbox-wrapper'>
                      <a href='#' class='close'></a>
                      <a href={`#lightbox-image-${k-1}`} class='arrow-left'></a>
                      <a href={`#lightbox-image-${k+1}`} class='arrow-right'></a>
                      <img src={`${floor.path}`} alt='' />
                    </div>
                  </div>
                )
                ))}
              </div>
            </div>
          </div>
        }
      
      {posts.locationPath && 
        <div className='project-location'>
          <h5
            style={{
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Konum
          </h5>
          <img
            src={posts.locationPath}
            alt=''
            style={{ height: "500px", width: "100%" }}
          />
        </div>
        }

      

      <Footer></Footer>
    </>
  );
};

export default ProjectDetail;
