import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationDot,
  faEnvelope,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import Iframe from "react-iframe";

const Contact = () => {

  const [posts, setData] = useState([]);
  
  useEffect(() => {
    axios
    .get('https://api.bilsermimarlik.com.tr/api/setting/1')
    .then((response) => {
    const json = response.data;
    console.log('json', json);
    setData(json);
    })
    .catch((error) => {
    console.log(error);
    });
    }, []);

  return (
    <>
      <Header></Header>
      <div className='header-title'>
        <span>İLETİŞİM</span>
      </div>
      <div className='main-contact'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-12 '>
              <ul>
                <li>
                  <span className='contact-icon'>
                    <FontAwesomeIcon icon={faLocationDot} size='1x' />
                  </span>
                  <span>
                  {posts.adresses}
                  </span>
                </li>
                <li>
                  <span className='contact-icon'>
                    <FontAwesomeIcon icon={faPhone} size='1x' />
                  </span>
                  <span>{posts.phone}</span>
                </li>
                <li>
                  <span className='contact-icon'>
                    <FontAwesomeIcon icon={faPhone} size='1x' />
                  </span>
                  <span>{posts.phone2}</span>
                </li>
                <li>
                  <span className='contact-icon'>
                    <FontAwesomeIcon icon={faEnvelope} size='1x' />
                  </span>
                  <span>{posts.email}</span>
                </li>
                <li>
                  <span className='contact-icon'>
                    <FontAwesomeIcon icon={faFax} size='1x' />
                  </span>
                  <span>FAX: {posts.fax}</span>
                </li>
              </ul>
            </div>
            <div className='col-md-6 col-sm-12 '>
              <ul className='main-contact-map '>
                <li>
                  <Iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3046.9091695501734!2d28.978764210536283!3d40.21108337135436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca1420cfa0bfb1%3A0x194d55394a05a3ec!2zw4dhxJ9sYXlhbiDEsMWfIE1lcmtlemk!5e0!3m2!1str!2str!4v1682256716020!5m2!1str!2str'
                    width='510'
                    height='380'
                    style='border:0;'
                    allowfullscreen=''
                    loading='lazy'
                    referrerpolicy='no-referrer-when-downgrade'
                  ></Iframe>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Contact;
