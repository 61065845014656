import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "../style.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "../style.css";
const Header = () => {
  return (
    <>
      <Navbar bg='light' expand='lg'>
        <Container className='header'>
          <img className='bilser-logo' src='..//images/logo.png'></img>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse className='justify-content-end'>
            <Nav>
              <Nav.Link as={Link} to='/'>
                Ana Sayfa
              </Nav.Link>
              <Nav.Link as={Link} to='/kurumsal'>
                Kurumsal
              </Nav.Link>
              <Nav.Link as={Link} to='/devameden'>
                Devam Eden Projeler
              </Nav.Link>
              <Nav.Link as={Link} to='/tamamlanan'>
                Tamamlanan Projeler
              </Nav.Link>
              <Nav.Link as={Link} to='/contact'>
                İletişim
              </Nav.Link>
              <div className='social-media'>
                <Nav.Link href='' style={{ marginRight: "8px" }}>
                  <FontAwesomeIcon icon={faFacebook} />
                </Nav.Link>
                <Nav.Link href='' style={{ marginRight: "8px" }}>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className='socialmedia-icon'
                  />
                </Nav.Link>
                <Nav.Link href=''>
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className='socialmedia-icon'
                  />
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
