import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

const Devameden = () => {

  const [posts, setData] = useState([]);
  useEffect(() => {
    axios
    .get('https://api.bilsermimarlik.com.tr/api/Project')
    .then((response) => {
    const json = response.data;
    console.log('json', json);
    setData(json);
    })
    .catch((error) => {
    console.log(error);
    });
    }, []);

  return (
    <>
      <Header></Header>
      <div className='header-title'>
        <span>DEVAM EDEN PROJELER</span>
      </div>
      <div className='projects-div'>
        <CardGroup className='card-group'>
        {posts.map((post, i) => ( 
                (
                  <div class='col-sm-4'>
                  <Card className='projects-card'>
                    <Card.Img
                      variant='top'
                      src={post.path}
                      className='card-image'
                    />
                    <Card.Body>
                      <Card.Title>{post.name}</Card.Title>
                      <Card.Link href={`/projectdetail/${post.id}`} className='card-link'>
                        Proje Detayı
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </div>
                )
                ))}
        </CardGroup>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Devameden;
