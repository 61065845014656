import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

const Tamamlanan = () => {

  const [postsOld, setDataOld] = useState([]);
    useEffect(() => {
      axios
      .get('https://api.bilsermimarlik.com.tr/api/Project/old')
      .then((response) => {
      const json = response.data;
      console.log('json', json);
      setDataOld(json);
      })
      .catch((error) => {
      console.log(error);
      });
      }, []);

  return (
    <>
      <Header></Header>
      <div className='header-title'>
        <span>TAMAMLANAN PROJELER</span>
      </div>
      <div className='projects-div'>
        <CardGroup className='card-group'>
          {postsOld.map((postOld, i) => ( 
                (
                  <div class='col-sm-4'>
                  <Card className='projects-card'>
                    <Card.Img
                      variant='top'
                      src={postOld.path}
                      className='card-image'
                    />
                    <Card.Body>
                      <Card.Title>{postOld.name}</Card.Title>
                      <Card.Link href={`/projectdetail/${postOld.id}`} className='card-link'>
                        Proje Detayı
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </div>
                )
                ))}
        </CardGroup>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Tamamlanan;
