import React, { useState, useEffect } from 'react';
import "../style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Iframe from "react-iframe";
import axios from 'axios';

import {
  faPhone,
  faLocationDot,
  faEnvelope,
  faFax,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const Footer = () => {

  const [posts, setData] = useState([]);
  
  
  useEffect(() => {
    axios
    .get('https://api.bilsermimarlik.com.tr/api/setting/1')
    .then((response) => {
    const json = response.data;
    console.log('json', json);
    setData(json);
    })
    .catch((error) => {
    console.log(error);
    });
    }, []);

    return (
      <>
        <div className='main-footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-lg-3'>
                <h6>Bilser Mimarlık</h6>
                <ul className='list-unstyled'>
                  <li>
                  {posts.footerText}
                  </li>
                </ul>
              </div>
              <div className='col-md-6 col-sm-6 col-lg-3'>
                <h6>Projeler</h6>
                <ul className='list-unstyled'>
                  <li>
                    <a href='/devameden'>
                      <span className='footer-icon'>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                      Devam Eden Projeler
                    </a>
                  </li>
                  <li>
                    <a href='/tamamlanan'>
                      <span className='footer-icon'>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                      Tamamlanan Projeler
                    </a>
                  </li>
                </ul>
              </div>
              <div className='col-md-6 col-sm-6 col-lg-3'>
                <h6>Harita</h6>
                <ul className='list-unstyled'>
                  <li>
                    <Iframe
                      className='footer-map'
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3046.9091695501734!2d28.978764210536283!3d40.21108337135436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca1420cfa0bfb1%3A0x194d55394a05a3ec!2zw4dhxJ9sYXlhbiDEsMWfIE1lcmtlemk!5e0!3m2!1str!2str!4v1682256716020!5m2!1str!2str'
                      width='220'
                      height='280'
                      style='border:0;'
                      allowfullscreen=''
                      loading='lazy'
                      referrerpolicy='no-referrer-when-downgrade'
                    ></Iframe>
                  </li>
                </ul>
              </div>
              <div className='col-md-6 col-sm-6 col-lg-3 '>
                <h6>İletişim</h6>
                <ul className='list-unstyled'>
                  <li>
                    <span className='footer-icon'>
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                    <span>
                    {posts.adresses}
                    </span>
                  </li>
                  <li>
                    <span className='footer-icon'>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <span>{posts.phone}</span>
                  </li>
                  <li>
                    <span className='footer-icon'>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <span>{posts.phone2}</span>
                  </li>  
                  <li>
                    <span className='footer-icon'>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <span>{posts.email}</span>
                  </li>
                  <li>
                    <span className='footer-icon'>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <span>{posts.fax}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Footer;
